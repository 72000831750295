$nav-height: 70px;
$nav-background: white;
$nav-font-size: 20px;
$nav-font-color: black;
$nav-font-color-hover: #F90;

header {
  overflow: auto;
  width: 100%;

  #header_box {
    margin: 0px auto;
    overflow: auto;
    position: relative;
    border-bottom: 1px solid;

    #external_media{
      width: auto;
      float: right;
      position: relative;

      .external_link{
        width: 60px;
        height: 60px;
        margin-top: 5px;
        margin-right: 5px;
        float: right;
        border-radius: 50%;
        -webkit-transition: all 0.25s ease 0s;
        -moz-transition: all 0.25s ease 0s;
        -ms-transition: all 0.25s ease 0s;
        -o-transition: all 0.25s ease 0s;
        transition: all 0.25s ease 0s;
      }

      .external_link:hover{
          background-color: #F90;
          border-radius: 50%;
      }
    }
  }

    #logo {
      float: left;
      padding: 5px 5px;
      margin: 0px;
      width: auto;
    }

    nav {
      float: right;
      width: auto;
      height: auto;
      clear: right;

      ul {
        list-style: none;
        float: right;
        li {
          float: left;
          position: relative;
          a {
            display: block;
            color: white;
            padding: 0 30px;
            color: $nav-font-color;
            text-decoration: none;
            font-family: $font-family;
            &:hover {
              color: $nav-font-color-hover;
              text-decoration: underline;
            }
            &:not(:only-child):after {
              padding-left: 4px;
              content: ' ▾';
            }
          }
        }
      }
    }
}

.nav-mobile {
  display: none;
  background: $nav-background;
  height: $nav-height;
  width: $nav-height;
  height: 0px;
}

@media screen and (min-width: $breakpoint) {
  .nav-list {
    display: block !important;
  }
  nav{
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

@media only screen and (max-width: $breakpoint) {
  .nav-mobile {
    display: block;
  }
  .nav-list {
    margin: 0px;
  }
  header{
    #header_box{
      width: 100%;

      #logo {
        float: none;
      }

      nav {
        overflow: auto;
        margin: 0px;
        padding: 0px;
        height: auto;
        text-align: center;
        float: left;
        width: 100%;

        ul {
          border-top: 1px solid black;
          border-bottom: 1px solid black;
          display: none;
          height: auto;
          float: left;
          width: 100%;
          padding: 0px;
          li {
            float: none;
            a {
              padding: 15px;
              line-height: 20px;
              border-top: 0.5px solid black;
            }
            ul li a {
              padding-left: 30px;
            }
          }
        }
      }
    }
  }
}

#nav-toggle {
  position: absolute;
  left: 18px;
  top: 22px;
  cursor: pointer;
  padding: 10px 35px 16px 0px;
  span,
  span:before,
  span:after {
    cursor: pointer;
    height: 5px;
    width: 35px;
    background: $nav-font-color;
    position: absolute;
    display: block;
    content: '';
    transition: all 300ms ease-in-out;
  }
  span:before {
    top: -10px;
  }
  span:after {
    bottom: -10px;
  }
  &.active span {
    background-color: transparent;
    &:before,
    &:after {
      top: 0;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
