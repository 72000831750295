hr.projects_divider{
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 0px;
    margin-bottom: 0px;
    clear:both;
}
.project_description{
    font-size: large;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 20px;
    color: #000;
    text-align: justify;
}
.project_date{
    width: 20%;
}
.project_image{
    float: right;
    margin-left: 0px;
    margin-bottom: 0px;
    padding-left: 20px;
    img{
        width: 450px;
        border: thin solid #000;
    }
}
.project_link{
    color: #000;
    text-decoration: none;
    -webkit-transition: all 0.25s ease 0s;
    -moz-transition: all 0.25s ease 0s;
    -ms-transition: all 0.25s ease 0s;
    -o-transition: all 0.25s ease 0s;
    transition: all 0.25s ease 0s;
}
.project_link:hover{
    text-decoration: underline;
}
.project_header_image{
    text-align: center;
    display: block;
    object-fit: cover;
    width: 800px;
    border: solid thin #000;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left:auto;
    margin-right:auto;
}
.project_subdescription{
    margin-left:5%;
    margin-right:5%;
    margin-top: 0px;
    padding: 0px;
    line-height: 30px;
}
.project_subdescription_image_L{
    float: left;
    margin-top: 0px;
    margin-right: 20px;
    width: 300px;
    border: solid thin #000;
}
.project_subdescription_image_R{
    float: right;
    margin-top: 0px;
    margin-left: 20px;
    width: 300px;
    border: solid thin #000;
}
project_subdescription_text{
    display: block;
    text-align: justify;
    font-family: $font-family;
    font-size: x-large;
    margin-bottom: 0px;
    line-height: 35px;
}
#project_information{
    display: block;
}
#project_content{
    display: block;
    padding: 0;
    margin: 0;
}

@media screen and (max-width:$breakpoint){
    .project_header_image{
        width: 500px;
    }
    .project_image{
        width: 40%;
        float: right;
        text-align: center;
        display: block;
        margin-left: auto;
        margin-right: 10px;
        margin-top: 0px;
        img{
            width: 100%
        }
    }
    .project_subdescription_image_L{
        float: right;
        display: block;
        margin-right: auto;
        margin-left: 20px;
        width: 40%;
    }
    .project_subdescription_image_R{
        float: right;
        display: block;
        margin-right: auto;
        margin-left:20px;
        width: 40%;
    }
}

@media screen and (max-width:580px){
    .project_header_image{
        width: 90%;
    }
}
