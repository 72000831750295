footer {
    font-family: $font-family;
    border-top: 1px solid;
    border-bottom: 1px solid;
    font-weight: 900;
    font-style: normal;
    font-size: x-small;
    text-align: center;
    margin-top: 0%;
    padding: 10px;
    clear: both;
}
