// Global Variables
$font-family:  Verdana, Geneva, sans-serif;
$breakpoint: 1100px;

// Global Rules
body {
    margin: 0;
    padding: 0;
}
h1{
    text-align: center;
    font-family: $font-family;
}
h2{
    font-family: $font-family;
    color: #000;
    margin-bottom: 0px;
    padding-bottom: 0px;
    -webkit-transition: all 0.25s ease 0s;
    -moz-transition: all 0.25s ease 0s;
    -ms-transition: all 0.25s ease 0s;
    -o-transition: all 0.25s ease 0s;
    transition: all 0.25s ease 0s;
}
h2:hover{
    text-decoration: underline;
}
h3{
    font-family: $font-family;
    clear: left;
    margin-bottom: 0px;
    padding-bottom: 0px;
}
h4{
    font-family: $font-family;
    font-size:x-large;
    margin-bottom: 10px;
    padding-bottom: 0px;
    margin-top: 0px;
    padding-top: 10px;
}
p{
    font-family: $font-family;
    font-size:24px;
    margin-bottom: 0px;
}
#logo{
    float: left;
    margin-bottom: 0px;
    width: 30%;
}
#logo img{
    width: 450px;
    height: 228px;
}
hr.gradient {
    border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
}
hr.solid{
    border-top: 1px solid #8c8b8b;
}

@media only screen and (max-width:$breakpoint){
    #logo{
        float: none;
        width: auto;
        height:228px;
        margin-top: 75px;
        margin-bottom: 15px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        display: block;
        clear: both;
    }
    h2{
        float: none;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
    }
    h3{
        float: none;
        text-align: left;
    }
}

@media screen and (max-width:580px){
    #logo{
        float: none;
        height: 200px;
        margin-top: 75px;
        margin-bottom: 15px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        clear: both;
    }
    #logo img{
        height: 200px;
        width: auto;
    }
}

@media screen and (max-width:480px){
    #logo img{
        height: 150px;
        width: auto;
    }
    #logo{
        float: none;
        height: 150px;
        margin-top: 65px;
        margin-bottom: 0px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        clear: both;
    }
}

// Import Subfiles
@import "about";
@import "footer";
@import "index";
@import "navigation";
@import "partners";
@import "projects";
